<template>
  <section
    v-if="sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
      :portrait="vars.isVerticalBool"
    />
    <!-- Variant 1 -->
    <div v-if="variants.v1 === variant" class="tw-max-w-xl xl:tw-max-w-none">
      <h3 class="tw-mb-4 xl:tw-mb-16 xl:tw-text-center">
        {{ vars.mainTitleText }}
      </h3>
      <div class="tw-grid tw-grid-cols-1 tw-items-center tw-justify-center tw-gap-4 xl:tw-grid-cols-12 xl:tw-gap-20">
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.mainTitleText"
          class="tw-w-full tw-rounded-xl tw-object-cover xl:tw-col-span-5"
          :class="{ 'xl:tw-order-last': vars.isReversedBool }"
        />
        <div
          v-if="vars.videoUrl"
          class="tw-w-full tw-object-cover xl:tw-col-span-5"
          :class="{ 'xl:tw-order-last': vars.isReversedBool }"
        >
          <library-video-embed
            :video-url="vars.videoUrl"
            :title="vars.mainTitleText"
            :is-popup="true"
            :cover-photo="vars.photoImage"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 xl:tw-col-span-7">
          <h6>
            {{ vars.titleText }}
          </h6>
          <p
            v-for="(item, index) in descriptions"
            :key="`infobox-descriptions-${index}`"
            class="xl:tw-whitespace-pre-line"
          >
            {{ item.description }}
          </p>
          <nuxt-link
            :external="true"
            class="tw-mt-2 xl:tw-mt-5"
            :class="`n-${vars.buttonTypeText}`"
            :to="vars.buttonLinkUrl"
          >
            {{ vars.buttonLabelText }}
          </nuxt-link>
        </div>
      </div>
    </div>

    <!-- Variant 2 -->

    <div
      v-if="variants.v2 === variant"
      class="tw-grid tw-grid-cols-12 tw-items-center tw-gap-y-4 xl:tw-gap-x-20"
      :style="`color: var(--c-${vars.textColor})!important;`"
    >
      <div v-if="vars.mainTitleText" class="tw-col-span-full tw-justify-center tw-text-center xl:tw-mb-16">
        <h3>{{ vars.mainTitleText }}</h3>
      </div>

      <div
        class="tw-col-span-12 tw-flex tw-flex-col tw-gap-4 xl:tw-col-span-7"
        :class="{
          'tw-order-last': vars.isReversedBool,
          'xl:!tw-col-start-6': vars.isVerticalBool && vars.isReversedBool,
        }"
      >
        <h2 v-if="vars.titleText && vars.isTitleBelow">
          {{ vars.titleText }}
        </h2>

        <h3 v-if="vars.titleText && !vars.isTitleBelow">
          {{ vars.titleText }}
        </h3>

        <p
          v-for="(item, index) in descriptions"
          :key="`infobox-descriptions-${index}`"
          class="tw-mt-4 xl:tw-mt-0 xl:tw-whitespace-pre-line"
          :style="`color: var(--c-${vars.textColor})`"
        >
          {{ item.description }}
        </p>
        <nuxt-link
          v-if="vars.buttonLinkUrl"
          :external="true"
          :to="vars.buttonLinkUrl"
          class="tw-mt-2 tw-w-fit xl:tw-mt-5"
          :class="`n-${vars.buttonTypeText}`"
        >
          {{ vars.buttonLabelText }}
        </nuxt-link>
      </div>

      <div
        class="tw-order-first tw-col-span-12 xl:tw-order-none xl:tw-col-span-5"
        :class="{
          'xl:!tw-order-first': vars.isReversedBool,
          'xl:!tw-col-span-4 xl:!tw-col-start-9': vars.isVerticalBool && !vars.isReversedBool,
          'xl:!tw-col-span-4 xl:!tw-col-start-1': vars.isVerticalBool && vars.isReversedBool,
        }"
      >
        <iframe
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-rounded-xl tw-object-cover"
          :src="vars.videoUrl"
          style="border-radius: var(--rounded)"
          frameborder="0"
          allowfullscreen
          :style="vars.isVerticalBool ? 'aspect-ratio: 9/16;' : 'aspect-ratio: 16/9;'"
        ></iframe>

        <LibraryImage
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :w-ratio="vars.isVerticalBool ? 9 : 645"
          :h-ratio="vars.isVerticalBool ? 16 : 430"
          style="border-radius: var(--rounded)"
        />

        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full"
          @click="isVisible = true"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            style="border-radius: var(--rounded)"
          />
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Variant 3 -->
    <div
      v-if="variants.v3 === variant"
      class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-items-center tw-gap-x-20 xl:tw-max-w-none xl:tw-grid-cols-12"
    >
      <h3 class="tw-mb-4 tw-self-end xl:tw-col-span-7">{{ vars.titleText }}</h3>

      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="vars.titleText"
        class="tw-w-full tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-3"
        :class="{ 'xl:tw-order-first': vars.isReversedBool }"
      />
      <div
        v-if="vars.videoUrl"
        class="tw-w-full tw-object-cover xl:tw-col-span-5 xl:tw-row-span-3"
        :class="{ 'xl:tw-order-first': vars.isReversedBool }"
      >
        <library-video-embed
          :video-url="vars.videoUrl"
          :title="vars.titleText"
          :is-popup="true"
          :cover-photo="vars.photoImage"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-self-start xl:tw-col-span-7 xl:tw-row-span-2">
        <p
          v-for="(item, index) in descriptions"
          :key="`infobox-descriptions-${index}`"
          class="tw-mt-4 xl:tw-whitespace-pre-line"
        >
          {{ item.description }}
        </p>

        <div class="tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-gap-8 xl:tw-mt-4">
          <div class="tw-mb-2 tw-flex tw-flex-row tw-items-center" v-for="(item, index) in items" :key="index">
            <NuxtImg :src="item.image" :alt="item.title" class="tw-w-10 xl:tw-w-14" />
            <div class="tw-ml-4">
              <h6>
                {{ item.title }}
              </h6>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      class="tw-px-4 tw-pb-8"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
      style="border-radius: var(--rounded)"
    >
      <LibraryPopupsVideoPopup
        v-if="vars.isPopupBool && vars.videoUrl"
        :videoUrl="vars.videoUrl"
        v-model:is-visible="isVisible"
        :portrait="vars.isVerticalBool"
      />

      <div
        v-if="variants.v2 === variant"
        class="tw-flex tw-flex-col tw-gap-y-4 tw-pt-8"
        :style="`color: var(--c-${vars.textColor})!important;`"
      >
        <div v-if="vars.mainTitleText" class="tw-mb-8">
          <h3>{{ vars.mainTitleText }}</h3>
        </div>

        <h3 v-if="vars.titleText">{{ vars.titleText }}</h3>

        <div class="tw-mt-4 tw-w-full">
          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-w-full tw-rounded-xl tw-object-cover"
            :src="vars.videoUrl"
            style="border-radius: var(--rounded)"
            frameborder="0"
            allowfullscreen
            :style="vars.isVerticalBool ? 'aspect-ratio: 9/16;' : 'aspect-ratio: 16/9;'"
          ></iframe>

          <LibraryImage
            v-if="vars.photoImage && !vars.videoUrl"
            :src="vars.photoImage"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            style="border-radius: var(--rounded)"
          />

          <div
            v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
            class="tw-relative tw-w-full"
            @click="isVisible = true"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-w-full tw-cursor-pointer"
              :w-ratio="vars.isVerticalBool ? 9 : 645"
              :h-ratio="vars.isVerticalBool ? 16 : 430"
              style="border-radius: var(--rounded)"
            />
            <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
              <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                <circle cx="81" cy="81" r="81" fill="white" />
                <polygon points="65,45 115,81 65,117" fill="black" />
              </svg>
            </button>
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-4 tw-pt-4">
          <p
            v-for="(item, index) in descriptions"
            :key="`infobox-descriptions-${index}`"
            class="tw-mt-4 tw-whitespace-pre-line"
            :style="`color: var(--c-${vars.textColor})`"
          >
            {{ item.description }}
          </p>
          <nuxt-link
            :external="true"
            :to="vars.buttonLinkUrl"
            class="tw-mt-4"
            :class="`n-${vars.buttonTypeText} tw-w-fit`"
          >
            {{ vars.buttonLabelText }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </template>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'InfoBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    variant() {
      return useVariant(this.component);
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },

    items() {
      return this.groupedVariables.items;
    },
  },
});
</script>
